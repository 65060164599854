import { Textarea } from "@chakra-ui/react";
import ResizeTextarea from "react-textarea-autosize";
import React from "react";
import { BrandColorPalette, TextAndShapesPalette } from "lib/styles/colors";

interface OwnProps {
  id?: string;
  value?: string;
  placeholder?: string;
  children?: React.ReactNode;
  isRequired?: boolean;
  isDisabled?: boolean;
  readOnly?: boolean;
  isInvalid?: boolean;
  minRows?: number;
  maxRows?: number;
  minH?: string | number;
  width?: string;
  borderColor?: string;
  noHoverBorder?: boolean;
}

export const AutoResizeTextarea = React.forwardRef((props: OwnProps, ref) => {
  const {
    id,
    value,
    placeholder,
    isRequired,
    isDisabled,
    isInvalid,
    noHoverBorder,
    ...otherProps
  } = props;

  return (
    <Textarea
      id={id}
      minH={props.minH || "unset"}
      overflow="hidden"
      w={props.width ?? "100%"}
      resize="none"
      // @ts-ignore
      ref={ref}
      value={value}
      isRequired={isRequired}
      isInvalid={isInvalid}
      isDisabled={isDisabled}
      transition="height none"
      placeholder={placeholder}
      minRows={props.minRows || 1}
      maxRows={props.maxRows || 300}
      readOnly={props.readOnly || false}
      as={ResizeTextarea}
      borderColor={props.borderColor ?? TextAndShapesPalette.INPUT_FIELD}
      borderWidth={"1px"}
      fontSize={"1rem"}
      fontWeight={400}
      letterSpacing={".75px"}
      lineHeight={"1.5rem"}
      color={TextAndShapesPalette.INPUT}
      _focus={
        noHoverBorder
          ? undefined
          : {
            borderColor: `${BrandColorPalette.BRANDING}`,
            borderWidth: "1px",
          }
      }
      _hover={
        noHoverBorder
          ? undefined
          : {
            borderColor: `${BrandColorPalette.BRANDING}`,
            borderWidth: "1px",
          }
      }
      {...otherProps}
    />
  );
});
