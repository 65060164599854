import { useCallback, useEffect, useState } from "react";
import { JobDetails } from "./types";
import { MethodType, ResponseStatus } from "lib/http/types";
import { call } from "lib/http/call";
import { ServiceEndpoints } from "lib/http/constants";
import { encodeToCamelCase } from "lib/http/formatForServer";

export function useGetJobByIdV2(params: {
  jobId: string | null;
  userRefId?: string | null;
  ssrJob: any;
}): {
    isLoading: boolean;
    error: string | null;
    job: JobDetails;
    jobDoesntExist: boolean;
  } {
  const { jobId, userRefId, ssrJob } = params;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [job, setJob] = useState<JobDetails>({} as JobDetails);
  const [jobDoesntExist, setJobDoesntExist] = useState<boolean>(false);
  const getJob = useCallback(async (jobId: string) => {
    setIsLoading(true);
    const authEndpoint = `v1/mariners/job/${jobId}?origin=Web`;
    const unAuthEndpoint = `v1/web/maritime/jobs/${jobId}`;

    const hasToken = document.cookie.includes("userToken");
    // console.warn("getJob - hasToken: ", hasToken);
    const endpoint = hasToken ? authEndpoint : unAuthEndpoint;
    const result = await call<JobDetails>({
      endpoint: `${ServiceEndpoints.JOBS}/${endpoint}`,
      method: MethodType.GET,
    });
    if (result.status === ResponseStatus.SUCCESS) {
      setJob(result.body);
    } else if (result.statusCode === 404) {
      setError(null);
      setJobDoesntExist(true);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (!jobId) {
      return;
    }
    getJob(jobId);
  }, [jobId, userRefId]);

  // default to SSR if no job yet
  const formattedJobs =
    ssrJob && !job?.refId ? (encodeToCamelCase(ssrJob) as JobDetails) : job;
  return {
    error,
    isLoading: isLoading && !ssrJob ? true : false,
    // dont show the reload if has ssrjob
    job: formattedJobs,
    jobDoesntExist,
  };
}
