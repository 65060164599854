import { Option } from "components/beta/Select";
import { Resume } from "core/auth/types";

const DEFAULT_LIMIT = 25;

export const getOffsetFromPage = (page: number, limit?: number): number => {
  if (!page) {
    return 0;
  }
  return (page - 1) * (limit ?? DEFAULT_LIMIT);
};

export const setupResumesArrayAsPickerData = (
  data: Resume[]
): Option<Resume>[] => {
  if (!data || data.length === 0) {
    return [];
  }
  const pickerMap: Option<Resume>[] = data.map(
    (currentOption: Resume, index: number) => {
      const pickerItem: Option<Resume> = {
        data: currentOption,
        name: currentOption.fileName,
        value: index,
      };
      return pickerItem;
    }
  );
  return pickerMap;
};
