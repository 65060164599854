import { JobDetails } from "./types";
import { upperCaseFirstLetter } from "lib/upperCaseFirstLetter";

export function formatDisplayFromLocation(
  city?: string,
  state?: string,
  countryAlpha2?: string
): string {
  let displayText = city ?? "";
  if (state && displayText.length) {
    displayText += `, ${state}`;
  } else if (state) {
    displayText = `${state}`;
  }
  if (countryAlpha2 && displayText.length) {
    displayText += `, ${countryAlpha2}`;
  } else if (countryAlpha2) {
    displayText = `${countryAlpha2}`;
  }
  return displayText;
}

export function formatEmploymentType(type?: string): string {
  if (!type) {
    return "-";
  }
  const lowerType = type.toLowerCase();

  switch (lowerType) {
    case "full_time":
    case "full-time":
      return "Full Time";
    case "part_time":
    case "part-time":
      return "Part Time";

    default:
      return upperCaseFirstLetter(lowerType);
  }
}

export interface JobQueryParams {
  offset?: number;
  limit?: number;
  jobTitle?: string;
  jobLocation?: string;
}

export function setupQueryParams(params: JobQueryParams): string {
  const { offset, limit, jobTitle, jobLocation } = params;
  let queryParams = `?origin=Web&limit=${limit}&offset=${offset ?? 0}`;

  if (!jobTitle && !jobLocation) {
    return queryParams;
  }

  // Setup query params
  if (jobTitle && jobTitle.length > 0) {
    const title = jobTitle.trim().split(" ").join("+");
    queryParams += `&q=${title}`;
  }
  if (jobLocation && jobLocation.length > 0) {
    const location = jobLocation.trim().split(" ").join("+");
    queryParams += `&location=${location}`;
  }
  return encodeURI(queryParams);
}

export function formatJobStructuredData(job: JobDetails): string {
  const formattedEmploymentType = job.employmentType
    ? job.employmentType?.replace(/ /g, "_").toUpperCase()
    : "OTHER";
  const structuredData = {
    "@context": "http://schema.org",
    "@type": "JobPosting",
    // "identifier": {
    //   "@type": "PropertyValue",
    //   "name": job.employer,
    //   "value": "1234567"
    // },
    datePosted: job.postedDate,
    description: `<p>${job.description}</p>`,
    // "validThrough" : "2017-03-18T00:00",
    employmentType: formattedEmploymentType,
    hiringOrganization: {
      "@type": "Organization",
      logo: job.employerLogoUrl,
      name: job.employer,
      sameAs: job.redirectUrl,
    },
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        addressCountry: job.country,
        addressLocality: job.city,
        addressRegion: job.state,
      },
    },
    title: job.title,
  };
  return JSON.stringify(structuredData);
}
