import useSWR from "swr";
import { orgFetcher } from "lib/http/api";
import { OrgInfo } from "./types";
import { encodeToCamelCase } from "lib/http/formatForServer";

export default function useJobOrganizations(params: {
  orgRefId?: string | null;
  userRefId?: string | null;
  ssrOrgInfo: any;
}) {
  const { orgRefId, userRefId, ssrOrgInfo } = params;
  const authEndpoint = `v1/organizations/${orgRefId}`;
  const unAuthEndpoint = `v1/organizations/${orgRefId}`;
  const { data, error, isValidating } = useSWR(
    !orgRefId || ssrOrgInfo ? null : userRefId ? authEndpoint : unAuthEndpoint,
    orgFetcher,
    {
      refreshWhenHidden: false,
      revalidateOnFocus: false,
    }
  );

  // @ts-ignore - need to fix axios <Any> type -- low priority
  const orgInfo: OrgInfo = data ? encodeToCamelCase(data) : null;
  const isLoading = (!data && !error) || isValidating;

  return {
    error,
    isLoading,
    orgInfo: ssrOrgInfo ? (encodeToCamelCase(ssrOrgInfo) as OrgInfo) : orgInfo,
  };
}
