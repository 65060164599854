import React from "react";

const BASE_WIDTH = 16;
const BASE_HEIGHT = 16;

export default function Timer(props: {
  height?: number;
  width?: number;
  color?: string;
}) {
  const width = props.width || BASE_WIDTH;
  const height = props.height || BASE_HEIGHT;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${BASE_WIDTH} ${BASE_HEIGHT}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 2C4.6875 2 2 4.6875 2 8C2 11.3125 4.6875 14 8 14C11.3125 14 14 11.3125 14 8C14 4.6875 11.3125 2 8 2Z"
        stroke="#4D4D4D"
        strokeMiterlimit="10"
      />
      <path
        d="M8 4V8.5H11"
        stroke="#4D4D4D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
