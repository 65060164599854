import { StatusColorPalette } from "lib/styles/colors";
import React from "react";

const BASE_WIDTH = 18;
const BASE_HEIGHT = 22;

export default function AppliedIcon(props: {
  height?: number;
  width?: number;
}) {
  const width = props.width || BASE_WIDTH;
  const height = props.height || BASE_HEIGHT;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.44773 20.3506H2.33088V1.64951H8.56457V6.32477C8.5658 6.73771 8.73039 7.13339 9.02238 7.42538C9.31438 7.71737 9.71005 7.88196 10.123 7.88319H14.7983V11H16.3567V6.32477C16.3594 6.22237 16.34 6.12058 16.2996 6.02642C16.2592 5.93226 16.199 5.84796 16.1229 5.77932L10.6684 0.324849C10.5998 0.248754 10.5155 0.188448 10.4214 0.14809C10.3272 0.107732 10.2254 0.0882833 10.123 0.0910853H2.33088C1.91794 0.0923186 1.52227 0.256905 1.23027 0.548899C0.938281 0.840893 0.773694 1.23657 0.772461 1.64951V20.3506C0.773694 20.7635 0.938281 21.1592 1.23027 21.4512C1.52227 21.7432 1.91794 21.9078 2.33088 21.909H5.44773V20.3506ZM10.123 1.96119L14.4866 6.32477H10.123V1.96119Z"
        fill={StatusColorPalette.SUCCESS_TEXT}
      />
      <path
        d="M16.5638 13.8635C16.1873 13.4869 15.5767 13.4869 15.2002 13.8635L10.9729 18.0907L9.35721 16.4366C8.98559 16.0561 8.37476 16.0525 7.99869 16.4286V16.4286C7.62377 16.8035 7.62603 17.4121 8.00371 17.7842L10.6739 20.4151C10.8696 20.6079 11.1842 20.6068 11.3784 20.4125L16.5638 15.2271C16.9404 14.8505 16.9404 14.24 16.5638 13.8635V13.8635Z"
        fill={StatusColorPalette.SUCCESS_TEXT}
      />
    </svg>
  );
}
