import React, { ReactElement } from "react";
import styled from "styled-components";
import Text, { TextType } from "components/Text";
import EmptyTableIcon from "lib/svgs/seagull/EmptyTable";

interface StyleProps {
  paddingTop?: string;
}
const Container = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  ${({ paddingTop }) => `
    padding-top: ${paddingTop ? paddingTop : "80px"};
  `}
`;

const StyledSVGContainer = styled.div`
  margin-bottom: 24px;
`;

interface Props {
  title?: string;
  description?: string;
  button?: ReactElement;
  paddingTop?: string;
}
export default function ErrorComponent({
  title,
  description,
  button,
  paddingTop,
}: Props): ReactElement {
  return (
    <Container paddingTop={paddingTop}>
      <StyledSVGContainer>
        <EmptyTableIcon />
      </StyledSVGContainer>
      {title && (
        <Text type={TextType.HEADING_SMALL} style={{ marginBottom: "24px" }}>
          {title}
        </Text>
      )}
      {description && <Text type={TextType.PARAGRAPH}>{description}</Text>}
      {button && button}
    </Container>
  );
}
